import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "about" */ '../views/layout'),
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home')
      },
      {
        path: '/user',
        name: '用户管理',
        component:()=>import('../views/user')
      },
      {
        path:'/menu',
        name:'菜品管理',
        component:()=>import('../views/menu/index.vue')
      },
      {
        path: '/modifyPwd',
        name: '修改密码',
        component:()=>import('../views/modifyPwd/index.vue')
      },
      {
        path: '/verification',
        name: '核销',
        component:()=>import('../views/verification/index.vue')
      },
      {
        path: '/record',
        name: '资金流水',
        component:()=>import('../views/record/index.vue')
      },
      {
        path: '/userRecord',
        name: '用户记录',
        component:()=>import('../views/userRecord/index.vue')
      },
      {
        path:'/message',
        name:'意见反馈',
        component:()=>import('../views/message/index.vue')
      },
      {
        path:'/dataAnalysis',
        name: '数据分析',
        component:()=>import('../views/dataAnalysis/index.vue')
      },
      {
        path:'/aiChat',
        name: 'AI助手',
        component:()=>import('../views/aiChat/index.vue')
      },
      {
        path:'/authorityManagement',
        name: '权限管理',
        component:()=>import('../views/authorityManagement/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
