import { createStore } from 'vuex'
import router from "@/router";
import {ElMessage, ElMessageBox} from "element-plus";
import axios from "@/util/axios";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    //安全退出功能
    Logout(){

      ElMessageBox.confirm(

          '您确定要退出登录吗？',
          '系统提示',
          {
            confirmButtonText:'确定',
            cancelButtonText:'取消',
            type:'warning'
          }
      ).then(async () => {
          window.sessionStorage.clear();
          window.localStorage.clear();
          router.replace('/login');//重定向
        }).catch(()=>{})

    }


  },
  modules: {
  }
})
