// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
//
// createApp(App).use(store).use(router).mount('#app')
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '././assets/styles/border.css'
import '././assets/styles/reset.css'
import '@/router/permission'
import './assets/styles/iconfont-weapp-icon.css'

import zhCn from 'element-plus/es/locale/lang/zh-cn';
import axios from "axios";


axios.defaults.withCredentials = true //携带cookie
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
const app= createApp(App);
app.use(store).use(router).use(ElementPlus,{locale:zhCn}).mount("#app");