// 通过导入router对象，对导航守卫进行了配置。导航守卫是Vue Router提供的一种机制，可以在路由发生变化之前或之后执行一些逻辑。在你提供的代码中，主要是使用beforeEach导航守卫，用于在路由跳转之前执行一些逻辑。
// 在Vue Router中，beforeEach导航守卫的回调函数接受三个参数：
//
// to: 表示即将要进入的目标路由对象，是一个包含有关要进入的路由信息的对象。
//
// from: 表示当前导航正要离开的路由对象，是一个包含有关要离开的路由信息的对象。
//
// next: 一个函数，用于进入下一个钩子。调用 next() 表示继续进行导航，调用 next('/path') 表示中断当前导航，跳转到另一个路径。
//
//


// import router from '@/router/index': 导入Vue Router的实例。这里假设项目的路由实例在 src/router/index.js 文件中。
//
// const whiteList = ['/login']: 定义一个白名单数组，里面包含了不需要登录即可访问的路由路径。在这个例子中，只有 /login 是白名单中的路由。
//
// router.beforeEach((to, from, next) => { ... }): 注册全局前置守卫，它会在路由改变之前执行。
//
// let token = window.sessionStorage.getItem("token");: 从浏览器的sessionStorage中获取名为 "token" 的值。
//
// console.log("token=" + token);: 打印出获取到的 token。
//
// console.log("to.path=" + to.path): 打印出即将跳转的路由路径。
//
// if (token) { ... } else { ... }: 根据是否存在 token 来判断用户是否登录。
//
// 如果有 token（即用户已登录）：
//
// 如果将要跳转的路由是 "/login"，则直接跳转到后台管理页面 ("/")。
// 否则，放行，继续执行下一步。
// 如果没有 token（即用户未登录）：
//
// 如果将要跳转的路由在白名单中，直接放行。
// 否则，跳转到登录页面 ("/login")。
// next(): 调用 next() 表示放行，继续执行下一步路由跳转。
//
// next("/login"): 调用 next("/login") 表示跳转到指定的路由（这里是登录页面）。

import router from "@/router/index";
import axios from "@/util/axios";
import {ElMessage, ElMessageBox} from "element-plus";

const whiteList = ['/login'];
router.beforeEach(async (to,from,next)=>{

    let token = window.sessionStorage.getItem("token");
    // console.log("to.path-----"+to.path);
    if(token){
        if(to.path === "/login"){
            next("/");  //放行，跳转到到后台首页
        }
        else {
            let userInfoJson = window.sessionStorage.getItem("userInfo");
            const userName = JSON.parse(userInfoJson).userName;

            //判断是否有权限访问
            if (to.path === "/authorityManagement" || to.path === "/user" || to.path === "/menu" || to.path === "/record" || to.path === "/userRecord" || to.path === "/message"){
                let res  = await axios.post('admin/isPermission/adminInfoName/'+userName+"/permission"+to.path);
                if(res.data.code === 0){

                    ElMessage({
                        type:'success',
                        message:'权限通过'
                     });

                    next(); //放行
                }
                else {
                    ElMessage({
                        type:'error',
                        message:res.data.msg
                    });
                    router.replace(from.path);//重定向
                }
            }

            next(); //放行
        }
    }
    else {
        if(whiteList.includes(to.path)){
            next();
        }
        else {
            next("/login");
        }
    }

})
